import { Component, Input } from '@angular/core';
import { formatDate } from 'src/app/utils/converter.utils';
@Component({
    selector: 'finstein-datatable-date-column[row]',
    templateUrl: './date-column.component.html',
    styleUrls: ['./date-column.component.scss'],
})
export class DateColumnComponent {
    @Input()
    row: any;

    @Input()
    prop = 'createdAt';
    @Input()
    pattern = 'DD.MM.YYYY';

    get value() {
        const value = formatDate(this.row[this.prop], this.pattern);
        this.row[`${this.prop}_value`] = value;
        return value;
    }
}
