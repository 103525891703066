/* src/app/shared/components/datatable-utils/components/date-column/date-column.component.scss */
span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ACTIVE,
.OPENED,
.APPROVED {
  background-color: #00b050 !important;
  color: white;
}
.VERIFICATION_PENDING,
.DELIVERED {
  background-color: #fff2cc !important;
  color: #4b4b4b;
}
.DATA_EXPORT_PENDING,
.SENT {
  background-color: #ffe699 !important;
  color: #4b4b4b;
}
.CONSULTATION_APPROVAL_PENDING {
  background-color: #bdd7ee !important;
  color: white;
}
.FINALIZED_WITHOUT_OPTIMIZATION {
  background-color: #ea6700 !important;
  color: white;
}
.READY_FOR_CONSULTATION_PROCESS,
.CLICKED,
.FINISHED,
.APPROVED,
.SIGNED_WITHOUT_OPTIMIZATION {
  background-color: #0070c0 !important;
  color: white;
}
.NOT_ELIGIBLE_FOR_OPTIMIZATION {
  background-color: #c00000 !important;
  color: white;
}
.IN_THE_CONSULTING_PROCESS {
  background-color: #c6e0b4 !important;
  color: white;
}
.DATA_MISSING {
  background-color: #c00000 !important;
  color: white;
}
.DATA_EXPORT_INCOMPLETE {
  background-color: #c00000 !important;
  color: white;
}
.WAITING_CONTRACT_SIGNATURE,
.WAITING_APPROVAL,
.UPLOADED {
  background-color: #ffc000 !important;
  color: #4b4b4b;
}
.REJECTED {
  background-color: #a6a6a6 !important;
  color: white;
}
.INACTIVE {
  background-color: #a6a6a6 !important;
  color: white;
}
.WAITING_ACCEPT_PRIVACY_TERMS {
  background-color: #fff2cc !important;
  color: #4b4b4b;
}
.COP_COMPLETED_CONTRACT_SIGNING_PENDING {
  background-color: #ffc000 !important;
  color: white;
}
.CONTRACT_EXPIRED {
  background-color: #ff0000 !important;
  color: white;
}
.CONTRACT_CANCELED,
.EMPLOYEE_UNKNOW {
  background-color: #a6a6a6 !important;
  color: white;
}
.WAITING_FOR_THE_GENERATION_OF_THE_CONTRACT {
  background-color: #ffc000 !important;
  color: white;
}
.PROBLEM_CONTRACT_GENERATION {
  background-color: #c00000 !important;
  color: white;
}
.ACTIVE_FINSTEIN_USER {
  background-color: #00b050 !important;
  color: white;
}
.NOT_AN_ACTIVE_FINSTEIN_USER {
  background-color: #f5d1bd !important;
  color: white;
}
.SALARY_INCREASE_PENDING,
.EMPLOYEE_KNOW_EMAIL_UNKNOW {
  background-color: #ffc000 !important;
  color: white;
}
.SALARY_INCREASE_PENDING {
  background-color: #ffc000 !important;
  color: white;
}
.PENDENT {
  background-color: #ffc000 !important;
  color: white;
}
.NOT_A_FINSTEIN_USER,
.INCREASE_APPROVED_AND_PENDING_CONFIRMATION,
.EMPLOYEE_KNOW_NOT_MADE_REQUEST {
  background-color: #ff9100 !important;
  color: white;
}
.PENDING_OPTIN,
.SALARY_INCREASE_APPROVED_AND_PENDING_CONFIRMATION {
  background-color: #ffe699 !important;
  color: #4b4b4b;
}
.link:hover {
  color: #5B6D80;
  cursor: pointer;
  text-decoration: underline;
}
.no-link,
.no-link:hover {
  cursor: default;
  text-decoration: none;
}
/*# sourceMappingURL=date-column.component-R2VQGZPP.css.map */
