<img
    alt="Icon"
    class="icon-text"
    [ngClass]="{ 'd-none': !iconLoaded }"
    (load)="iconLoaded = true"
    [src]="getIconURL()"
/>
<div *ngIf="!iconLoaded" class="spinner-grow text-primary">
    <span class="sr-only">Loading...</span>
</div>
