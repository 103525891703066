import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BaseModalModule } from 'src/app/core/components/base-modal/base-modal.module';
import { ButtonsColumnComponent } from './components/buttons-column/buttons-column.component';
import { ConfigColumnsComponent } from './components/config-columns/config-columns.component';
import { ConfigModalComponent } from './components/config-columns/config-modal/config-modal.component';
import { DatatableSuportComponent } from './components/datatable-support/datatable-suport.component';
import { DateColumnComponent } from './components/date-column/date-column.component';
import { IconColumnComponent } from './components/icon-column/icon-column.component';
import { SearchAllTableComponent } from './components/search-all-table/search-all-table.component';
import { SearchColumnComponent } from './components/search-column/search-column.component';
import { StatusColumnComponent } from './components/status-column/status-column.component';
@NgModule({
    declarations: [
        StatusColumnComponent,
        SearchColumnComponent,
        SearchAllTableComponent,
        ButtonsColumnComponent,
        DatatableSuportComponent,
        ConfigColumnsComponent,
        ConfigModalComponent,
        IconColumnComponent,
        DateColumnComponent
    ],
    exports: [
        StatusColumnComponent,
        SearchColumnComponent,
        SearchAllTableComponent,
        ButtonsColumnComponent,
        DatatableSuportComponent,
        ConfigColumnsComponent,
        IconColumnComponent,
        DateColumnComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatRippleModule,
        MatTooltipModule,
        NgxPermissionsModule,
        MatDialogModule,
        BaseModalModule,
        MatCheckboxModule,
        FormsModule,
        DragDropModule,
        MatMenuModule,
        MatDialogModule,
    ],
})
export class DatatableUtilsModule {}
