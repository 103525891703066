<div class="d-flex align-items-center">
    <button
        type="button"
        class="btn-action"
        (click)="openColumnConfig()"
        finsteinTooltip="common.edit-columns"
        matRipple
    >
        <i class="material-icons">settings</i>
    </button>
</div>
