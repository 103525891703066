import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { get, isEmpty } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'finstein-search-column[table][rows]',
    templateUrl: './search-column.component.html',
    styleUrls: ['./search-column.component.scss'],
})
export class SearchColumnComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    table: DatatableComponent;
    @Input()
    rows: Array<any>;
    @Input()
    disabledColumns: Array<string> = [];

    @ViewChild('searchByColumn')
    searchByColumn: ElementRef;

    searchSubject = new Subject<{ value; column }>();
    searchSubscription: Subscription;
    showInputConfig: {
        [prop: string]: { showInput: boolean; currentValue?: string };
    } = {};

    public ngOnInit() {
        this.searchSubscription = this.searchSubject
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(({ value, column }) => {
                this.updateFilter(value, column);
            });
    }

    ngAfterViewInit(): void {
        this.table.headerComponent.columns
            .filter((column) => !this.disabledColumns.includes(column.prop))
            .forEach((column) => {
                if (column.sortable) {
                    column.headerTemplate = this.searchByColumn;
                    this.showInputConfig[column.prop] = { showInput: false };
                }
            });
    }

    ngOnDestroy(): void {
        this.table.rows = this.rows;
    }

    updateFilter(value, column) {
        const val = (value || '').toLowerCase().trim();

        this.showInputConfig[column.prop].currentValue = val;

        const newRows = this.rows.filter((item) => {
            const filters = Object.keys(this.showInputConfig)
                .map((key) => ({ prop: key, ...this.showInputConfig[key] }))
                .filter((e) => e.showInput && !isEmpty(e.currentValue));
            for (const filter of filters) {
                const itemValue =
                    get(item, `${filter.prop}_value`) || get(item, filter.prop);

                if (
                    !itemValue ||
                    itemValue
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.currentValue) === -1 ||
                    !filter.currentValue
                ) {
                    return false;
                }
            }
            return true;
        });

        this.table.rows = newRows;
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    onInputSearchEvent(event, column) {
        this.searchSubject.next({ value: event.target.value, column });
    }

    toggleInput(column) {
        const config = this.showInputConfig[column.prop];
        config.showInput = !config.showInput;
        if (!config.showInput) {
            this.updateFilter(config.currentValue, column);
        }
    }

    showInput(column) {
        return this.showInputConfig[column.prop].showInput;
    }
}
