/* src/app/shared/components/datatable-utils/components/config-columns/config-modal/config-modal.component.scss */
.list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 9999 !important;
  max-height: 500px;
}
.box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  z-index: 9999 !important;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.box:last-child {
  border: none;
}
.list.cdk-drop-list-dragging .box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
small {
  padding-left: 1px;
}
.icon-error {
  position: relative;
  width: auto;
  bottom: 31px;
  margin-top: 0;
  float: right;
  margin-right: 8px;
  color: #DC3545;
}
.error-message {
  height: 20px;
}
/*# sourceMappingURL=config-modal.component-VFZBSKWK.css.map */
