<finstein-base-modal
    title="common.edit-columns"
    bodyClass="none"
    [disableClose]="true"
>
    <finstein-circle-edit header></finstein-circle-edit>
    <div content>
        <div
            class="d-flex flex-column pr-5 pl-5 list"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
        >
            <ng-container *ngFor="let column of columns; let i = index">
                <div class="box" cdkDrag>
                    <mat-checkbox [(ngModel)]="column.enabled">
                        {{ column.name || 'common.checkbox' | translate }}
                    </mat-checkbox>

                    <i
                        class="material-icons"
                        [matTooltip]="'tooltips.drag-and-drop' | translate"
                        tooltipPos="left"
                    >
                        drag_indicator
                    </i>
                </div>
            </ng-container>
        </div>
        <small class="form-text text-danger text-center" *ngIf="!canSave()">
            {{ "validations.minColumnLength" | translate : { size: 1 } }}
        </small>
    </div>

    <finstein-action-confirm
        actions
        type="done"
        [doneBtnTitle]="'system.save'"
        (doneClick)="save()"
        [doneBtnDisable]="!canSave()"
    >
    </finstein-action-confirm>
</finstein-base-modal>
