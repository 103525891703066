<div [matTooltip]="getStatusTooltip()">
    <div
        class="d-flex align-content-center justify-content-center"
        [ngClass]="class"
    >
        <i
            *ngIf="icon"
            class="material-icons-outlined status-icon"
            [ngClass]="iconClass"
        >
            {{ icon }}
        </i>

        <span
            class="cursor-default badge"
            [ngClass]="getStatusClass()"
            [ngStyle]="style"
        >
            <strong>{{ getStatusValue() }}</strong>
        </span>
    </div>
</div>
