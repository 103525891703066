import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { STATUS_DESCRIPTIONS, STATUS_LABELS } from 'src/app/app.constants';
@Component({
    selector: 'finstein-datatable-status-column[row]',
    templateUrl: './status-column.component.html',
    styleUrls: ['./status-column.component.scss'],
})
export class StatusColumnComponent {
    @Input()
    row: any;

    @Input()
    prop = 'status';

    @Input()
    params: any;

    @Input()
    class: string;

    @Input()
    iconClass: string;

    @Input()
    statusClass: string;

    @Input()
    style: string | any;

    @Input()
    forceCustomStatusValue = false;

    @Input()
    customStatusValue: string;

    @Input()
    customStatusClassFn: any;

    @Input()
    forceCustomStatusDescription = false;

    @Input()
    customStatusTooltip: string;

    @Input()
    icon: string;

    @Input()
    resolveStatus = true;

    constructor(private translateService: TranslateService) {}

    get value() {
        return this.row[this.prop];
    }

    getStatusClass() {
        if (this.customStatusClassFn) {
            return this.customStatusClassFn(this.row);
        } else if (this.statusClass) {
            return this.statusClass;
        } else if (this.value) {
            return this.value;
        }
    }

    getStatusValue() {
        // TODO: Please @davileal review this resolveStatus with customStatusValue
        // if (this.resolveStatus) {
            let value: string = this.customStatusValue || this.value || '';
            if (!this.forceCustomStatusValue) {
                const defaultStatusLabel = STATUS_LABELS[value?.replace(/ /g, '_')];
                if (defaultStatusLabel) {
                    value = defaultStatusLabel;
                }
            }
            if (value || this.value) {
                value = this.translateService.instant(value || this.value, this.params);
                this.row[`${this.prop}_value`] = value;
            }
            return value;
        // }
    }

    getStatusTooltip() {
        let value: string = this.customStatusTooltip || this.value || '';

        if (this.forceCustomStatusDescription) {
            return value;
        } else {
            const defaultStatusDesc =
                STATUS_DESCRIPTIONS[value?.replace(/ /g, '_')];
            if (defaultStatusDesc) {
                value = defaultStatusDesc;
            }
        }
        if (value || this.value) {
            value = this.translateService.instant(value || this.value, this.params);
            if ((this.customStatusTooltip || this.value) === value) {
                value = undefined;
            }
        }
        return value;
    }
}
