import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SideMenuService {

    private collapsedEvent: Subject<boolean> = new Subject();
    private isSidebarCollapsed = false;

    constructor() {}

    toggleSidebar() {
        this.isSidebarCollapsed = !this.isSidebarCollapsed;
        this.collapsedEvent.next(this.isSidebarCollapsed);
    }

    toggleSidebarPin() {
        this.isSidebarCollapsed = false;
        this.collapsedEvent.next(this.isSidebarCollapsed);
    }

    get opened() {
        return this.isSidebarCollapsed;
    }

    get onSidebarChange() {
        return this.collapsedEvent.asObservable();
    }

}
