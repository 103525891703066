import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { get } from 'lodash';

@Component({
    selector: 'finstein-search-all-table[table][rows]',
    templateUrl: './search-all-table.component.html',
    styleUrls: ['./search-all-table.component.scss'],
})
export class SearchAllTableComponent implements OnDestroy, AfterViewInit {
    @Input()
    searchPlaceholder = 'system.search-items';
    @Input()
    table: DatatableComponent;
    @Input()
    rows: Array<any>;
    @Input()
    customSearchProps: Array<{ prop: string }>

    filterColumnsKey: Array<{ prop: string }>;

    constructor() {}

    ngAfterViewInit(): void {
        this.filterColumnsKey = this.table.headerComponent.columns.filter(
            (column) => column.sortable
        );

        if (this.customSearchProps) {
            this.filterColumnsKey.push(...this.customSearchProps);
        }        
    }

    ngOnDestroy(): void {
        this.table.rows = this.rows;
    }

    updateFilter(value) {
        const val = (value || '').toLowerCase().trim();

        const newRows = this.rows.filter((item) => {
            for (const { prop } of this.filterColumnsKey) {
                const itemValue = get(item, `${prop}_value`) || get(item, prop);
                if (
                    (itemValue &&
                        itemValue.toString().toLowerCase().indexOf(val) !==
                            -1) ||
                    !val
                ) {
                    return true;
                }
            }
            return false;
        });

        this.table.rows = newRows;
        // Whenever the filter changes, always go back to the first page
        this.table.offset = 0;
    }

    reset(value) {
        if (!value?.length) {
            this.table.rows = this.rows;
            // Whenever the filter changes, always go back to the first page
            this.table.offset = 0;
        }
    }
}
