<ng-container *ngIf="showDropdown; else rowButtons">
    <div class="btn-group w-100" role="group">
        <button
            type="button"
            class="btn-action"
            mat-button
            [matMenuTriggerFor]="menu"
        >
            <i class="material-icons">more_vert</i>
        </button>
        <mat-menu #menu="matMenu">
            <ng-container
                *ngFor="
                    let button of buttons;
                    trackBy: trackButton;
                    let i = index
                "
            >
                <ng-container *ngIf="isVisible(button); else emptyButton">
                    <button
                        [id]="i"
                        *ngxPermissionsOnly="button.permissions"
                        [disabled]="isDisabled(button)"
                        [ngStyle]="getStyle(button)"
                        [ngClass]="getClass(button)"
                        (click)="onClick(button)"
                        matRipple
                        mat-menu-item
                        [attr.data-test]="button.dataTest"
                    >
                        <span class="d-flex justify-content-around">
                            <i class="material-icons mr-1">{{ button.icon }}</i>
    
                            {{ getTitle(button) | translate : button.params }}
                        </span>
                    </button>
                </ng-container>
            </ng-container>
        </mat-menu>
    </div>
</ng-container>
<ng-template #rowButtons>
    <div class="d-flex w-100" style="justify-content: space-between">
        <ng-container
            *ngFor="let button of buttons; trackBy: trackButton; let i = index"
        >
            <ng-container *ngIf="isVisible(button); else emptyButton">
                <button
                    class="btn-action ml-1"
                    *ngxPermissionsOnly="button.permissions"
                    [disabled]="isDisabled(button)"
                    [ngClass]="getClass(button)"
                    [ngStyle]="getStyle(button)"
                    (click)="onClick(button)"
                    [matTooltip]="
                        getTooltip(button) | translate : button.tooltipParams
                    "
                    matRipple
                    [attr.data-test]="button.dataTest"
                >
                    <i class="material-icons">{{ button.icon }}</i>
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #emptyButton> </ng-template>
