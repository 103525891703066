/* src/app/shared/components/datatable-utils/components/search-all-table/search-all-table.component.scss */
.search-input {
  width: 350px;
  height: auto;
  display: flex;
  align-items: center;
}
.search-input input {
  width: 90%;
  border: none;
  border-bottom: 2px solid #e4e6f9;
  outline: none;
  padding: 10px 5px 10px 5px;
  background-color: white;
}
/*# sourceMappingURL=search-all-table.component-6KNBNGGU.css.map */
