<ng-template #searchByColumn let-column="column">
    <ng-container *ngIf="showInput(column); else searchBtn">
        <i
            (click)="toggleInput(column)"
            matRipple
            class="material-icons cursor-pointer mr-2"
        >
            close
        </i>

        <input
            maxlength="150"
            class="form-control mr-2"
            type="text"
            [placeholder]="
                'common.search-by' | translate : { column: column.name | translate }
            "
           
            (keyup)="onInputSearchEvent($event, column)"
        />
    </ng-container>
    <ng-template #searchBtn>
        <i
            matRipple
            class="material-icons cursor-pointer"
            (click)="toggleInput(column)"
        >
            search
        </i>
        <span class="datatable-header-cell-label">{{
            column.name | translate
        }}</span>
    </ng-template>
</ng-template>
