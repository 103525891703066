<div class="search-input">
    <input
        maxlength="150"
        aria-label="Quick search"
        type="text"
        placeholder="{{ searchPlaceholder | translate }}"
        (keyup.enter)="updateFilter(searchInput.value)"
        (keyup.delete)="reset(searchInput.value)"
        (keyup.backspace)="reset(searchInput.value)"
        #searchInput
    />
    <button
        type="button"
        class="btn-action"
        (click)="updateFilter(searchInput.value)"
        matRipple
    >
        <i class="material-icons">search</i>
    </button>
</div>
