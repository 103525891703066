import { Component, Input } from '@angular/core';
import { FIREBASE_STORAGE_BUCKET_BASE_URL } from 'src/app/app.constants';

@Component({
    selector: 'finstein-datatable-icon-column[url]',
    templateUrl: './icon-column.component.html',
    styleUrls: ['./icon-column.component.scss'],
})
export class IconColumnComponent {

    @Input()
    url: string;

    iconLoaded: boolean;

    getIconURL() {
        return `${FIREBASE_STORAGE_BUCKET_BASE_URL}${this.url}`;
    }
}
