import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { SideMenuService } from 'src/app/core/services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';

interface IDataTableQueryParams {
    sortDir: string;
    sortBy: string;
    page: number;
}

@Component({
    selector: 'finstein-datatable-suport',
    template: '',
})
export class DatatableSuportComponent implements AfterViewInit, OnInit {

    queryParams: IDataTableQueryParams;

    @Input()
    table: DatatableComponent;

    @Input()
    pageSize = 20;

    @Input()
    externalPaging = false;

    @Output()
    sort = new EventEmitter();

    constructor(
        private translateService: TranslateService,
        private sideMenu: SideMenuService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.queryParams = {
                page: params.page ? Number(params.page) : 0,
                sortBy: params.sortBy ?? 'createdAt',
                sortDir: params.sortDir ?? 'desc'
            };
        });
    }

    ngAfterViewInit(): void {
        if (!this.table.columnMode) {
            this.table.columnMode = 'force';
        }

        if (!this.table.headerHeight) {
            this.table.headerHeight = 50;
        }

        if (!this.table.footerHeight) {
            this.table.footerHeight = 50;
        }

        if (!this.table.rowHeight) {
            this.table.rowHeight = 60;
        }

        if (!this.table.limit) {
            this.table.limit = this.pageSize;
        }

        if (!this.table.pageSize) {
            this.table.pageSize = this.pageSize;
        }

        this.table.scrollbarH = true;
        this.table.virtualization = false;
        this.table.externalPaging = this.externalPaging;

        this.table.sorts = [{prop: this.queryParams.sortBy, dir: this.queryParams.sortDir}];
        this.table.offset = this.queryParams.page || 0;

        this.table.messages = {
            totalMessage: this.translateService.instant('common.total'),
            selectedMessage: '',
            emptyMessage: `
                <div class="jumbotron jumbotron-fluid pl-4 fade-in" #emptyMessage>
                    <div class="container d-flex align-items-center flex-column">
                        <h1 class="display-4 d-flex align-items-center text-nowrap" translate>
                            ${this.translateService.instant(
                'common.no-search-elements'
            )}
                            <i class="material-icons search-off">search_off</i>
                        </h1>
                    </div>
                </div>
            `,
        };

        this.sideMenu.onSidebarChange.subscribe(() => {
            this.table.ngAfterViewInit();
        });


        this.table.sort.subscribe((ev) => {
            const sort = ev.sorts[0];
            this.sort.emit(sort);
            if (sort) {
                this.router.navigate(
                    [],
                    {
                        preserveFragment: true,
                        relativeTo: this.activatedRoute,
                        queryParams: {sortDir: sort.dir, sortBy: sort.prop},
                        queryParamsHandling: 'merge',
                    });
            }
        });

        this.table.page.subscribe((ev) => {
            this.router.navigate([],
                {
                    preserveFragment: true,
                    relativeTo: this.activatedRoute,
                    queryParams: {page: ev.offset},
                    queryParamsHandling: 'merge',
                });
        });
    }
}
