import { Component, Input, AfterViewInit } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { ConfigModalComponent } from './config-modal/config-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'finstein-datatable-config-columns[table]',
    templateUrl: './config-columns.component.html',
    styleUrls: ['./config-columns.component.scss'],
})
export class ConfigColumnsComponent implements AfterViewInit {
    @Input()
    table: DatatableComponent;

    initialColumns: TableColumn[];
    currentColumns: TableColumn[];

    constructor(private dialog: MatDialog) {}

    ngAfterViewInit(): void {
        this.initialColumns = this.table.headerComponent.columns;
        this.currentColumns = this.initialColumns;
    }

    get columns() {
        return this.table.columns;
    }

    openColumnConfig() {
        this.dialog
            .open(ConfigModalComponent, {
                panelClass: 'curved-modal',
                data: {
                    initialColumns: this.initialColumns,
                    currentColumns: this.currentColumns,
                },
            })
            .afterClosed()
            .subscribe((res: { columns: TableColumn[], initialColumns: TableColumn[] }) => {
                if (res.columns?.length > 1) {
                    // Set default width for columns
                    res.columns.forEach(column => column.width = 60);
                    this.currentColumns = res?.columns;
                    this.table.columns = this.currentColumns;
                    // Update current column orders
                    this.initialColumns = res.initialColumns;

                    // Request table life cicle
                    this.table.ngOnInit();
                    this.table.ngAfterViewInit();
                    this.table.ngAfterContentInit();
                }
            });
    }
}
