import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './config-modal.component.html',
    styleUrls: ['./config-modal.component.scss'],
})
export class ConfigModalComponent implements OnInit {
    columns: any[];

    constructor(
        private dialogRef: MatDialogRef<ConfigModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { initialColumns: TableColumn[]; currentColumns: TableColumn[] }
    ) {}

    ngOnInit() {
        this.columns = this.data.initialColumns.map((column, index) => {
            const remainsEnabled = this.data.currentColumns.find(
                (currentColumnEnabled) =>
                    currentColumnEnabled.prop === column.prop
            );

            column['enabled'] = !!remainsEnabled;
            return column;
        });
    }

    public save() {
        this.dialogRef.close({
            columns: this.columns.filter((column) =>  column['enabled']),
            initialColumns: this.columns
        });
    }

    canSave() {
        return this.columns.filter((column) => column['enabled']).length > 1;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.columns,
            event.previousIndex,
            event.currentIndex
        );
    }
}
