/* src/app/shared/components/datatable-utils/components/buttons-column/buttons-column.component.scss */
.btn-group {
  vertical-align: unset !important;
  cursor: pointer;
}
.li-disabled {
  pointer-events: none;
  display: none;
}
::ng-deep .mat-menu-panel {
  max-width: none !important;
}
/*# sourceMappingURL=buttons-column.component-BDJAP4VA.css.map */
